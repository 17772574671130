import React from 'react';
import {
  Container, Image, Title, Carrot,
} from './styled';
import shopping_cart_image from './shopping_cart_image.svg';
import shipping_image from './shipping_image.svg';
import checkout_image from './checkout_image.svg';

function Heading({ page }) {
  let image, title;

  switch (page) {
  case 0: {
    image = shopping_cart_image;
    title = 'SHOPPING CART';
    break;
  }
  case 1: {
    image = shipping_image;
    title = 'SHIPPING & BILLING DETAILS';
    break;
  }
  case 2: {
    image = checkout_image;
    title = 'CHECKOUT';
    break;
  }
  default:
  }
  return (
    <Container>
      <Image src={image} />
      <Title>{title}</Title>
      <Carrot />
    </Container>
  );
}

export default Heading;
