import React from 'react';
import Row from '../../../../Row';
import RadioButtonGroup from '../../../../RadioButtonGroup';
import { Finalizing } from './styled.js';
import MoreInformation from './MoreInformation';

function Insurance({ form, updateForm }) {
  return (
    <>
      <Row style={{ margin: '30px auto 10px' }}>
        <Finalizing>Before finalizing your order, would you like to purchase So Bridely Dress Insurance?* <i>(Required Field)</i></Finalizing>
        <RadioButtonGroup value={form.purchaseInsurance} values={[true, false]} update={(val) => updateForm('purchaseInsurance', val)} labels={['Yes', 'No']} />
      </Row>
      <MoreInformation />
    </>
  );
}

export default Insurance;