import React from 'react';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import Input from '../../../Input';
import { Title } from '../BillingAddress/styled';
import {
  Container, InputContainer, LockIconStyle,
} from './styled';
import LockIcon from './LockIcon.svg';

function PaymentMethod({
  firstName, lastName, setFirstName, setLastName, disabled,
}) {
  const style = { base: { ':disabled': { color: '#6B6F81' } } };
  return (
    <>
      <Title style={{ marginTop: 20 }} >Payment Method</Title>
      <Container>
        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '20px 1.875vw',
        }}>
          <Input disabled={disabled} required update={setFirstName} value={firstName} label="First Name" />
          <Input disabled={disabled} required update={setLastName} value={lastName} label="Last Name" />
          <InputContainer>
            <CardExpiryElement options={{ disabled, style }} />
          </InputContainer>
          <InputContainer>
            <CardCvcElement options={{ disabled, style }} />
          </InputContainer>
          <InputContainer style={{ gridArea:'span 1 / span 2' }}>
            <CardNumberElement options={{ disabled, style }} />
            <LockIconStyle src={LockIcon} />
          </InputContainer>
        </div>
      </Container>
    </>
  );
}

export default PaymentMethod;