import React from 'react';
import { formatAmount } from '../../../../../helpers/Format';
import { getSubTotal } from '../../../../Desktop/Checkout/Summary/helpers';
import { insuranceAmount } from '../../../../../helpers/Checkout';
import { Container, Text } from './styled';

function Totals({
  shippingCost, items, form,
}) {
  const subTotal = getSubTotal(items);
  const shipping = shippingCost ? `$${shippingCost}` : '*calculated at checkout*';
  let total = subTotal + (shippingCost || 0);

  if (form.purchaseInsurance) {
    total += insuranceAmount;
  }

  const tax = total * .008;
  return (
    <Container>
      <Text>Subtotal</Text>
      <Text>${formatAmount(subTotal)}</Text>
      {
        form.purchaseInsurance && (
          <>
            <Text>Insurance</Text>
            <Text>${formatAmount(insuranceAmount)}</Text>
          </>
        )
      }
      <Text>Shipping</Text>
      <Text>{shipping}</Text>
      <Text>Tax</Text>
      <Text>${formatAmount(tax)}</Text>
      <Text>Total</Text>
      <Text>${formatAmount(total + tax)}</Text>
    </Container>
  );
}

export default Totals;
