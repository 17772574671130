import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #7DBEC8;
  letter-spacing: 0;
  line-height: 24px;
  cursor: pointer;
`;

export const Info = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 10px;
  color: #6B969D;
  letter-spacing: 0;
  line-height: 20px;
  opacity: ${props => props.show ? 1 : 0};
  transform: ${props => props.show ? 'translateY(0)' : 'translateY(10px)'};
  transition: opacity .5s, transform .5s, position 1s .5s;
`;