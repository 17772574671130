import React from 'react';
import Row from '../../../Row';
import {
  Container, Title, Input, InputTitle, FormSection,
} from './styled';

function BillingAddress({
  updateForm, form, disabled,
}) {
  return (
    <Container>
      <Title>Billing Address</Title>
      <Row>
        <FormSection style={{ marginRight: 10 }}>
          <Input disabled={disabled} name="first-name" required value={form.shippingLastName} onChange={({ target: { value } }) => updateForm('shippingLastName', value)}/>
          <InputTitle>First Name</InputTitle>
        </FormSection>
        <FormSection>
          <Input disabled={disabled} name="last-name" required value={form.shippingFirstName} onChange={({ target: { value } }) => updateForm('shippingFirstName', value)}/>
          <InputTitle>Last Name</InputTitle>
        </FormSection>
      </Row>
      <FormSection>
        <Input disabled={disabled} name="ship-address" required value={form.shippingStreet1} onChange={({ target: { value } }) => updateForm('shippingStreet1', value)}/>
        <InputTitle>Address Line 1</InputTitle>
      </FormSection>
      <FormSection>
        <Input disabled={disabled} name="ship-address-2" value={form.shippingStreet2} onChange={({ target: { value } }) => updateForm('shippingStreet2', value)}/>
        <InputTitle>Address Line 2</InputTitle>
      </FormSection>
      <FormSection>
        <Input disabled={disabled} name="ship-city" required value={form.shippingCity} onChange={({ target: { value } }) => updateForm('shippingCity', value)}/>
        <InputTitle>City</InputTitle>
      </FormSection>
      <FormSection>
        <Input disabled={disabled} name="ship-state" required value={form.shippingState} onChange={({ target: { value } }) => updateForm('shippingState', value)}/>
        <InputTitle>State</InputTitle>
      </FormSection>
      <FormSection>
        <Input disabled={disabled} name="ship-zip" required value={form.shippingZip} onChange={({ target: { value } }) => updateForm('shippingZip', value)}/>
        <InputTitle>Zip</InputTitle>
      </FormSection>
    </Container>
  );
}

export default BillingAddress;
