import React, { useState } from 'react';
import Image from '../../../../../ImageBasic';
import Row from '../../../../../Row';
import {
  Container, Title, Info,
} from './styled.js';
import Icon from './icon.svg';

function MoreInformation() {
  const [showMore, setShowMore] = useState(false);
  return (
    <Container>
      <Row>
        <Image style={{
          marginRight: 10, transition: '.5s', transform: showMore ? 'rotate(135deg)': 'rotate(0deg)',
        }} src={Icon} />
        <Title onClick={() => setShowMore(!showMore)}>More Information</Title>
      </Row>
      <Info show={showMore}>Insurance allows buyers the opportunity to return the purchased gown within 48 hours of its delivery, for reasons such as: the item does not fit, preference change, etc. Insurance is a flat fee of $100, while returns are susceptible to a 10% restocking fee + shipping costs.</Info>
    </Container>
  );
}

export default MoreInformation;