import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { connect } from 'react-redux';
import {
  useStripe, useElements, CardNumberElement,
} from '@stripe/react-stripe-js';
import { editCartItem, createPurchase } from '../../../actions/checkoutActions';
import * as apiHelpers from '../../../helpers/API';
import Navigation from '../../Text/Navigation';
import { Container } from './styled';
import Heading from './Heading';
import Page1 from './Page1';
import Page2 from './Page2';
var debounce = require('lodash.debounce');


function Checkout({
  editCartItem, createPurchase, shippingCost,
  setShippingDetails, checkoutItems, products, accountData,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [form, setForm] = useState({});
  const [page, setPage] = useState(0);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const { profile: { email, phoneNumber } = {} } = accountData || {};
  const {
    shippingCity, shippingStreet1, shippingStreet2, shippingLastName,
    shippingFirstName, shippingState, shippingZip,
  } = form;

  useEffect(() => {
    const _items = Object.keys(checkoutItems).map((id) => products[id]).filter((exists) => !!exists);
    let _total = 0;

    for (var id of Object.keys(checkoutItems)) {
      if (products[id]) {
        _total += parseFloat(products[id].salePrice);
      }
    }

    const _subTotal = _total;
    _total = shippingCost ? _total + shippingCost : _total;
    setTotal(_total);
    setItems(_items);
    setSubTotal(_subTotal);
  }, [checkoutItems, products, shippingCost]);


  useEffect(() => {
    if (
      shippingCity && shippingState && shippingZip
         && shippingStreet1 && shippingStreet2 && shippingFirstName && shippingLastName
    ) {
      updateShippingPriceThrottled.current(shippingCity, shippingStreet1, shippingStreet2, shippingFirstName, shippingState, shippingZip, shippingLastName);
    }
  }, [shippingCity, shippingStreet1, shippingStreet2, shippingFirstName, shippingState, shippingZip, shippingLastName, updateShippingPriceThrottled]);

  const updateShippingPriceThrottled = useRef(debounce((shippingCity, shippingStreet1, shippingStreet2, shippingFirstName, shippingState, shippingZip, shippingLastName) => {
    const address = {
      city: shippingCity,
      state: shippingState,
      street1: shippingStreet1,
      street2: shippingStreet2,
      zip: shippingZip,
    };
    const name = `${shippingFirstName} ${shippingLastName}`;

    apiHelpers.getShippingCostFromAddress(address, name, email, phoneNumber)
      .then(({
        rate, shipmentId, rateId,
      }) => {
        setShippingDetails(rate, shipmentId, rateId);
        updateForm('shippingCost', true);
      });
  }, 1300));


  const updateForm = useCallback((key, value) => {
    setForm({
      ...form,
      [key]: value,
    });
  }, [form]);

  const handleSubmit = useCallback(async () => {
    const card = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });

    if (!error && paymentMethod) {
      createPurchase({
        ...form,
        ccToken: paymentMethod.id,
      });
    }
  }, [elements, form, stripe, createPurchase]);

  let content;

  switch (page) {
  case 0: content = <Page1
    checkoutItems={checkoutItems}
    items={items}
    editCartItem={editCartItem}
    shippingCost={shippingCost}
    next={() => setPage(page + 1)}
    form={form}
    updateForm={updateForm}
  />;
    break;
  case 2:
  case 1: content = <Page2
    page={page}
    subTotal={subTotal}
    total={total}
    items={items}
    shippingCost={shippingCost}
    next={() => setPage(page + 1)}
    form={form}
    updateForm={updateForm}
  />;
    break;
  }
  return (
    <Container onSubmit={(e) => {
      e.preventDefault();

      if (!items.length) {
        return alert('You must have items in your cart to continue');
      }

      if (page < 2) {
        setPage(page + 1);
      } else {
        handleSubmit();
      }
      return false;
    }}
    >
      <Heading page={page} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        { page > 0 && <Navigation onClick={() => setPage(page - 1)} style={{ margin: 20 }}>Back</Navigation>}
        {content}
      </div>
    </Container>
  );
}

const mapDispatchToProps = {
  editCartItem,
  createPurchase,
};

const mapStateToProps = (state) => ({
  ...state.shopReducer,
  ...state.userReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
