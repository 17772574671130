import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 40px;
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.865vw;
  color: #A7ABBD;
  letter-spacing: 0;
  line-height: 3.865vw;
`;

export const Item = styled.div`
  margin-top: 3.382vw;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #EDEEF4;
  border-top: 1px solid #EDEEF4;
  padding: 4.831vw 0;
`;

export const Image = styled.img`
  width: 31.40vw;
  height: 21.74vw;
  object-fit: contain;
`;

export const Name = styled.div`
text-transform: capitalize;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.382vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.797vw;
`;

export const Color = styled.div`
text-transform: capitalize;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.382vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.797vw;
`;

export const Material = styled.div`
text-transform: capitalize;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.382vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.797vw;
`;

export const Price = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 3.382vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.797vw;
`;