import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 216px;
  margin-top: 136px
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 21px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 38px;
`;