import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 30px;
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #E4E5EC;
  box-shadow: 0 2px 1px 0 rgba(0,0,0,0.03);
  height: 40px;
  width: calc(100% - 10px);
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #898EA2;
  letter-spacing: 0;
  line-height: 22px;
  padding-left: 10px;
`;
export const LockIconStyle = styled.img`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
`;