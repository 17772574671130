import React from 'react';
import StandardButton from '../../../StandardButton';
import PaymentMethod from '../PaymentMethod';
import BillingAddress from '../BillingAddress';
import OrderDetails from '../OrderDetails';
import { Container } from './styled';

function Page2(props) {
  const {
    form, page, updateForm,
  } = props;

  const { firstName, lastName } = form;
  const lastPage = page === 2;
  return (
    <Container>
      <BillingAddress disabled={lastPage} {...props} />
      <PaymentMethod
        disabled={lastPage}
        firstName={firstName}
        lastName={lastName}
        setLastName={(value) => updateForm('lastName', value)}
        setFirstName={(value) => updateForm('firstName', value)} />
      {lastPage && <OrderDetails {...props} />}
      <StandardButton type="submit" label={lastPage ? 'Confirm & Pay' : 'Next'} style={{ marginTop: 30 }}/>
    </Container>
  );
}

export default Page2;
