import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Circle = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  box-shadow: 0px 0px 0.5px 0px rgba(0,0,0,1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Fill = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: #EAAFB7;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

export const Label = styled.label`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 15px;
`;