import styled from 'styled-components';

export const Container = styled.form`
  
`;

export const CheckoutButton = styled.button`
  border: none;
  -webkit-appearance: none; 
  background: #FE95A2;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 4.348vw;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 4.831vw;
  width: 89.13vw;
  height: 15.46vw;
  cursor: pointer;
  margin: auto;
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7.246vw;
  }
  > img {
    margin-right: 7.246vw;
  }
`;