import React from 'react';
import {
  Container, Title, Button, Input, ButtonContainer,
} from './styled';

function PromotionCode() {
  return (
    <Container>
      <Title>Have a promotion code?</Title>
      <ButtonContainer>
        <Input />
        <Button>Submit</Button>
      </ButtonContainer>
    </Container>
  );
}

export default PromotionCode;
