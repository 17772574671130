import React from 'react';
import { formatAmount } from '../../../../helpers/Format';
import Totals from '../Page1/Totals';
import { Title } from '../BillingAddress/styled';
import {
  Container, Item, Image, Name, Color, Price,
} from './styled';

function OrderDetails(props) {
  const { items } = props;
  return (
    <Container>
      <Title>Your order</Title>
      {
        items.map(({
          photos: [image], color, name, salePrice, material,
        }, index) => (
          <Item key={index}>
            <Image src={image} />
            <div style={{ marginLeft: 30, flex: 1 }}>
              <div>
                <Name>{name}</Name>
                <Color>{color} {material}</Color>
              </div>
              <div style={{
                display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
              }}>
                <Price>${formatAmount(salePrice)}</Price>
              </div>
            </div>
          </Item>
        ))
      }
      <Totals {...props} />
    </Container>
  );
}

export default OrderDetails;
