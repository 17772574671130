import styled from 'styled-components';

export const Container = styled.div`
  height: 13.00vw;
  background-color: #E1CFCD;
  padding: 0 21.56vw;
  justify-content: space-between;
  display: flex;
  align-items: center;
  position: relative;
  > img {
    width: 2.813vw;
    object-fit: contain;
  }
`;

export const ImageLabel = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  max-width: 11.69vw;
  margin-top: 1.250vw;
`;

export const ImageSeparator = styled.div`
  height: 6.750vw;
  width: 1px;
  background-color: #E4E5EC;
  margin: 0 9.000vw;
`;

export const Carrot = styled.div`
  height: 8.188vw;
  width: 8.188vw;
  background-color: #E1CFCD;
  position: absolute;
  transform: rotate(45deg);
  bottom: -1vw;
  left: ${props => props.left};
  z-index: 0;
  transition: left .5s;
`;

export const PageDetail = styled.div`
  height: 101px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-family: Lato-Medium;
  font-size: 14px;
  color: #6B969D;
  letter-spacing: 0.35px;
  line-height: 24px;
`;