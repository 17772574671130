import React from 'react';
import StandardButton from '../../../StandardButton';
import CheckoutItems from './CheckoutItems';
import PromotionCode from './PromotionCode';
import Totals from './Totals';
import Insurance from './Insurance';
import { Container, Title } from './styled';

function Page1(props) {
  return (
    <Container>
      <Title>Product Details</Title>
      <CheckoutItems {...props} />
      <Insurance {...props} />
      <PromotionCode {...props} />
      <Totals {...props} />
      <StandardButton style={{ marginTop: 30 }} type="submit" label="Next" />
    </Container>
  );
}

export default Page1;
