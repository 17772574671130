import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5.556vw 0;
  margin-top: 5.314vw;
`;

export const Text = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.865vw;
  color: #6B6F81;
  :nth-child(even) {
    text-align: right;
  }
`;

export const Total = styled.div`
  padding-top: 7.729vw;
  border-top: 1px solid #EDEEF4;
  display: flex;
  width: 100%;
  justify-content: space-between;
  grid-area: span 1 / span 2;
`;

export const TotalValue = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 5.797vw;
  color: #666EE8;
  letter-spacing: 0;
  text-align: right;
`;