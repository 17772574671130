import React from 'react';
import { Container, Label } from './styled';

function StandardButton({
  label, onClick, style, ...other
}) {
  return (
    <Container {...other} style={style} onClick={onClick}>
      <Label>{label}</Label>
    </Container>
  );
}

export default StandardButton;
