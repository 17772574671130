import React from 'react';
import Column from '../../../Column';
import Grid from '../../../Grid';
import Row from '../../../Row';
import RadioButtonGroup from '../../../RadioButtonGroup';
import Divider from '../../../Divider';
import Summary from '../Summary';
import { HeadingText, Finalizing } from './styled.js';
import CheckoutItem from './CheckoutItem';
import PromotionCode from './PromotionCode';
import MoreInformation from './MoreInformation';

function Page1({
  items, form, updateForm, discount,
  shippingCost, editCartItem, getDiscount,
  page,
}) {
  return (
    <Column style={{ margin: '120px 215px', flex: 1 }}>
      <Grid layout="8fr 1fr" style={{ width: '100%', justifyContent: 'space-between' }}>
        <Row>
          <HeadingText>Product Detail</HeadingText>
          <HeadingText style={{ marginLeft: 'auto' }}>Price</HeadingText>
        </Row>
        <Row></Row>
      </Grid>
      <Divider style={{ marginTop:20 }} />
      {
        items.map((listItem) => (
          <CheckoutItem editCartItem={editCartItem} key={listItem.__id} {...listItem} />
        ))
      }
      <Row style={{ marginTop: 48, width: '100%' }}>
        <Column style={{ marginRight: 'auto' }}>
          <Column style={{ marginBottom: 50 }}>
            <Row>
              <Finalizing>Before finalizing your order, would you like to purchase So Bridely Dress Insurance?* <i>(Required Field)</i></Finalizing>
              <RadioButtonGroup value={form.purchaseInsurance} values={[true, false]} update={(val) => updateForm('purchaseInsurance', val)} labels={['Yes', 'No']} />
            </Row>
            <MoreInformation />
          </Column>
          <PromotionCode getDiscount={getDiscount} update={(val) => updateForm('couponCode', val)} value={form.couponCode} />
        </Column>
        <Summary page={page} discount={discount} form={form} shippingCost={shippingCost} items={items} />
      </Row>
    </Column>

  );
}

export default Page1;