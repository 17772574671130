import React from 'react';
import { formatAmount } from '../../../../../helpers/Format';
import {
  Container, Item, Image, Name, Color, Price, Remove,
} from './styled';
import RemoveIcon from './Remove.svg';

function CheckoutItems({ items, editCartItem }) {
  return (
    <Container>
      {
        items.map(({
          photos: [image], color, name, salePrice, material, __id,
        }, index) => (
          <Item key={index}>
            <Image src={image} />
            <div style={{ marginLeft: '2.657vw', flex: 1 }}>
              <div>
                <Name>{name}</Name>
                <Color>{color} {material}</Color>
              </div>
              <div style={{
                display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
              }}>
                <Price>${formatAmount(salePrice)}</Price>
                <Remove onClick={() => editCartItem(__id, 0)} src={RemoveIcon} />
              </div>
            </div>
          </Item>
        ))
      }
    </Container>
  );
}

export default CheckoutItems;
