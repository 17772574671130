import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
//components
import Layout from '../components/Layout';
import CheckoutDesktop from '../components/Desktop/Checkout';
import CheckoutMobile from '../components/Mobile/Checkout';
//actions
import { setMetaTags } from '../actions/homeActions';
import { editCartItem, setShippingDetails } from '../actions/checkoutActions';
//styles
import './style.css';
import { getQueryString } from '../helpers/Fetch';
import Loader from '../components/Loader';

function Checkout(props) {
  const {
    user, loading, accountData, setMetaTags,
  } = props;

  useEffect(() => {
    const description = 'Checkout with your dress now';
    const image = '';
    const url = window.location.href;
    const title = `SoBridely`;

    setMetaTags({
      description, image, url, title,
    });
  }, [setMetaTags]);

  if (!user && !loading) {
    const goTologin = confirm('You must be logged in to buy a dress, please login in first.');

    if (goTologin) {
      const payload = {
        redirect: true,
        type: 'buy_dress',
      };
      navigate(`/login?${getQueryString(payload)}`);
    } else {
      navigate('/shop');
    }
  }

  if (!accountData) {
    return <Loader />;
  }
  return (
    <Layout
      mobile={<CheckoutMobile {...props} />}
      desktop={<CheckoutDesktop {...props} />} />
  );
}

const mapStateToProps = (state) => ({
  ...state.shopReducer,
  ...state.userReducer,
  ...state.loaderReducer,
});

const mapDispatchToProps = {
  editCartItem,
  setShippingDetails,
  setMetaTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);