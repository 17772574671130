import { CardElement } from '@stripe/react-stripe-js';
import { getFromDatabase } from '../Firebase';

export const insuranceAmount = 10000;

export function getTotal(products, checkoutItems, shippingCost, purchaseInsurance) {
  let total = 0;

  for (var id of Object.keys(checkoutItems)) {
    if (products[id]) {
      total += parseFloat(products[id].salePrice / 100);
    }
  }

  const subtotal = parseFloat(total) + shippingCost;
  const tax = subtotal * .008;
  const amount = subtotal + tax + (purchaseInsurance ? (insuranceAmount / 100) : 0);
  return parseInt(Number(amount).round(2) * 100);
}

export async function getDiscount(chargeAmount, couponCode) {
  let discountAmount = 0;

  if (couponCode && couponCode.toUpperCase) {
    let couponAmount = await getFromDatabase(`coupons/${couponCode.toUpperCase()}`);

    if (parseInt(couponAmount) == 0) {
      discountAmount = chargeAmount;
    } else if (couponAmount && couponAmount.match && couponAmount.match(/%/)) {
      const percentDiscount = parseInt(couponAmount.replace(/%/, '')) / 100;
      chargeAmount = (chargeAmount * percentDiscount);
    } else if (parseInt(couponAmount)) {
      discountAmount = parseInt(couponAmount);
    }
  }
  return discountAmount;
}

export async function handleStripeSubmit(elements, stripe) {
  const card = elements.getElement(CardElement);
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card,
  });

  if (error || !paymentMethod) {
    console.log(error);
    return alert('Please try entering payment information again');
  } else {
    return paymentMethod.id;
  }
}