import styled from 'styled-components';

export const Left = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #6B969D;
  letter-spacing: 0;
`;

export const Right = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #124C56;
  letter-spacing: 0;
`;