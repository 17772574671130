import styled from 'styled-components';

export const Carrot = styled.div`
  height: 14.49vw;
  width: 14.49vw;
  background-color: #E1CFCD;
  position: absolute;
  transform: rotate(45deg);
  bottom: calc(-14.49vw / 2);
  z-index: 0;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  background-color: #E1CFCD;
  height: 50.24vw;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.382vw;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 5.797vw;
  margin-top: 4.831vw;
`;

export const Image = styled.img`
  max-width: 10.87vw;
  max-width: 10.63vw;
  object-fit: contain;
`;