import React from 'react';
import {
  Container, ImageSeparator, ImageLabel, Carrot,
  PageDetail,
} from './styled';
import ShoppingCartIcon from './ShoppingCartIcon.svg';
import BillingIcon from './BillingIcon.svg';
import CheckoutIcon from './CheckoutIcon.svg';

class Heading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top: 0,
      left: 0,
    };
    this.setCarrotPosition = this.setCarrotPosition.bind(this);
  }
  componentDidMount() {
    this.setCarrotPosition();
  }
  componentWillReceiveProps(props) {
    this.setCarrotPosition(props);
  }
  setCarrotPosition(props = this.props) {
    let selectedElement, left;

    switch (props.activeIndex) {
    default:
    case 0:
      selectedElement = $('#checkout-shopping-cart-heading');
      left = '21.1vw';
      break;
    case 1:
      selectedElement = $('#checkout-billing-details-heading');
      left = '47.1vw';
      break;
    case 2:
      selectedElement = $('#checkout-checkout-heading');
      left = '71.9vw';
      break;
    }

    if (selectedElement && selectedElement.position()) {
      const top = `calc(${selectedElement.position().top}px + 2vw)`;

      this.setState({
        top,
        left,
      });
    }
  }
  render() {
    const { changeIndex } = this.props;
    return (
      <>
        <PageDetail>
        Home&nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;&nbsp;Cart&nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;&nbsp;Shopping Cart
        </PageDetail>
        <Container>
          <div onClick={() => changeIndex(0)} id="checkout-shopping-cart-heading" style={{
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}>
            <img src={ShoppingCartIcon} />
            <ImageLabel>Shopping Cart</ImageLabel>
          </div>
          <ImageSeparator style={{ marginRight: '6.688vw' }} />
          <div onClick={() => changeIndex(1)} id="checkout-billing-details-heading" style={{
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}>
            <img src={BillingIcon} />
            <ImageLabel>Shipping & Billing Details</ImageLabel>
          </div>
          <ImageSeparator style={{ marginLeft: '6.688vw' }} />
          <div id="checkout-checkout-heading" onClick={() => changeIndex(2)} style={{
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}>
            <img src={CheckoutIcon} />
            <ImageLabel>Checkout</ImageLabel>
          </div>
          <Carrot top={this.state.top} left={this.state.left} />
        </Container>
      </>
    );
  }
}
export default Heading;
