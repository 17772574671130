export function getSubTotal(items) {
  let subTotal = 0;

  items.forEach(({ salePrice }) => {
    subTotal += parseInt(salePrice);
  });
  return subTotal;
}

export function getTax(items, shippingCost) {
  let subTotal = 0;

  items.forEach(({ salePrice }) => {
    subTotal += parseInt(salePrice);
  });

  const total = subTotal + (shippingCost || 0);
  return total * .008;
}