import React from 'react';
import Column from '../../../../Column';
import Row from '../../../../Row';
import Divider from '../../../../Divider';
import Input from '../../../../ThemedInput';
import ThemedDropdown from '../../../../ThemedDropdown';
import { STATE_OPTIONS } from '../../../../../constants';
import { Title } from './styled.js';

function ShippingDetails({
  updateForm, form, disabled,
}) {
  return (
    <Column style={{ width :'100%', marginBottom: 47 }}>
      <Row style={{ width: '100%' }}>
        <Title>Shipping Address</Title>
      </Row>
      <Divider style={{ margin: '18px 0' }} />
      <Column style={{ width: 'calc(100% - 80px)', margin:'auto' }}>
        <Row style={{ width:'100%', marginBottom: 15 }}>
          <Input disabled={disabled} style={{ marginRight: 30 }} label="First Name" value={form.firstName} update={(val) => updateForm('shippingFirstName', val)} />
          <Input disabled={disabled} label="Last Name" value={form.lastName} update={(val) => updateForm('shippingLastName', val)} />
        </Row>
        <Input disabled={disabled} style={{ width:'100%', marginBottom: 15 }} label="Address Line 1" value={form.street1} update={(val) => updateForm('shippingStreet1', val)} />
        <Input disabled={disabled} style={{ width:'100%', marginBottom: 15 }} label="Address Line 2" value={form.street2} update={(val) => updateForm('shippingStreet2', val)} />
        <Row style={{ width:'100%', marginBottom: 15 }}>
          <Input disabled={disabled} style={{ flex: .57, marginRight: 30 }} label="City" value={form.city} update={(val) => updateForm('shippingCity', val)} />
          <ThemedDropdown options={STATE_OPTIONS} disabled={disabled} style={{ flex: .1, marginRight: 30 }} label="State" value={form.state} update={(val) => updateForm('shippingState', val)} />
          <Input disabled={disabled} style={{ flex: .33 }} label="Zip Code" value={form.zip} update={(val) => updateForm('shippingZip', val)} />
        </Row>
      </Column>
    </Column>
  );
}

export default ShippingDetails;