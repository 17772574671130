import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Row from '../../../Row';
import Summary from '../Summary';
import Column from '../../../Column';
import { Container, Title } from './styled';
import PaymentDetails from './PaymentDetails';
import ShippingDetails from './ShippingDetails';
import OrderSummary from './OrderSummary';

function Page2({
  updateForm, form, disabled, shippingCost, items, total,
  findingShippingPrice, discount, page,
}) {
  const _total = shippingCost ? total + shippingCost : total;
  return (
    <Container>
      <Row style={{ alignItems: 'flex-start' }}>
        <Column style={{ marginRight: 128, marginBottom: 60 }}>
          {!disabled && <Title style={{ marginBottom: 38 }}>Choose Your Payment Method</Title>}
          <ShippingDetails disabled={disabled} form={form} updateForm={updateForm} />
          <div style={{ position:'relative' }}>
            <center>
              {findingShippingPrice && <CircularProgress thickness={5} size={60} style={{
                top: '40%', left: '45%', position:'absolute', zIndex: 2,
              }}
              />}
            </center>
            <PaymentDetails disabled={disabled || !shippingCost} form={form} total={(_total).toFixed(2)} updateForm={updateForm} />
          </div>
        </Column>
        <Column>
          <OrderSummary items={items} />
          <Summary page={page} discount={discount} form={form} buttonText={!disabled ? 'Next' : 'Confirm And Pay'} items={items} showTax={disabled} shippingCost={shippingCost} />
        </Column>
      </Row>
    </Container>
  );
}

export default Page2;