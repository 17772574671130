import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #EDEEF4;
  padding: 4.831vw 0;
`;

export const Image = styled.img`
  width: 31.40vw;
  height: 21.74vw;
  object-fit: contain;
`;

export const Name = styled.div`
text-transform: capitalize;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.382vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.797vw;
`;

export const Color = styled.div`
text-transform: capitalize;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.382vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.797vw;
`;

export const Material = styled.div`
text-transform: capitalize;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.382vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.797vw;
`;

export const Price = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 3.382vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.797vw;
`;

export const Remove = styled.img`
  width: 3.865vw;
  height: 3.865vw;
  object-fit: contain;
  margin-left: 2.415vw;
  cursor: pointer;
`;
