import React from 'react';
import Column from '../../../Column';
import Row from '../../../Row';
import Button from '../../../Button2';
import Divider from '../../../Divider';
import { formatAmount } from '../../../../helpers/Format';
import { insuranceAmount } from '../../../../helpers/Checkout';
import { Left, Right } from './styled.js';
import { getSubTotal } from './helpers.js';

function Summary({
  shippingCost, items,
  buttonText = 'Checkout', form,
  discount, page,
}) {
  const subTotal = getSubTotal(items);
  const shipping = shippingCost ? `$${formatAmount(shippingCost)}` : '*calculated at checkout*';
  let total = subTotal + (shippingCost || 0);

  if (form.purchaseInsurance) {
    total += insuranceAmount;
  }

  const tax = total * .008;
  return (
    <Column style={{ width: 335 }}>
      <Row style={{
        width:'100%', justifyContent: 'space-between', marginBottom: 15,
      }}
      >
        <Left>Subtotal</Left>
        <Right>${formatAmount(subTotal)}</Right>
      </Row>
      <Row style={{
        width:'100%', justifyContent: 'space-between', marginBottom: 15,
      }}
      >
        <Left>Shipping</Left>
        <Right>{shipping}</Right>
      </Row>
      {form.purchaseInsurance &&
      <Row style={{
        width:'100%', justifyContent: 'space-between', marginBottom: 15,
      }}
      >
        <Left>Insurance</Left>
        <Right>${formatAmount(insuranceAmount)}</Right>
      </Row>
      }
      {
        <Row style={{
          width:'100%', justifyContent: 'space-between', marginBottom: 15,
        }}
        >
          <Left>Tax</Left>
          <Right>${formatAmount(tax)}</Right>
        </Row>
      }
      {discount ?
        <Row style={{
          width:'100%', justifyContent: 'space-between', marginBottom: 15,
        }}
        >
          <Left>Coupon Code</Left>
          <Right>- ${formatAmount(discount)}</Right>
        </Row> : null
      }
      <Divider style={{ margin: '18px 0' }} />
      <Row style={{
        width:'100%', justifyContent: 'space-between', marginBottom: 15,
      }}
      >
        <Left>Total</Left>
        <Right>${formatAmount(total + tax - discount)}</Right>
      </Row>
      <Button disabled={!shippingCost && page === 2} style={{ height: 60, width: 335 }}>{buttonText}</Button>
    </Column>
  );
}

export default Summary;