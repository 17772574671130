import styled from 'styled-components';

export const Container = styled.div`
  margin: 120px 215px;
`;

export const HeadingText = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #6B969D;
  letter-spacing: 0;
  line-height: 22px;
`;

export const Finalizing = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 24px;
  i {
    color: #7DBEC8;
  }
`;