import React from 'react';
import Button from '../../../../Button1';
import Row from '../../../../Row';
import {
  Container, Heading, PlainInput,
} from './styled.js';

function PromotionCode({
  update, value, getDiscount,
}) {
  return (
    <Container>
      <Heading>Have A Promotion Code</Heading>
      <Row>
        <PlainInput value={value} onChange={(e) => update(e.target.value)} />
        <Button onClick={getDiscount} type="button" style={{ height: 44 }}>SUBMIT</Button>
      </Row>
    </Container>
  );
}

export default PromotionCode;