import React, { useRef, useEffect } from 'react';
import Row from '../Row';
import {
  Circle, Fill, Label,
} from './styled.js';

function RadioButtonGroup({
  labels, update, values, value, style,
}) {
  return values.map((_value, i) => <RadioButton update={update} value={_value} selected={value} label={labels[i]} style={style} />);
}


function RadioButton({
  value, selected, label, update, style,
}) {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.checked = typeof(selected) !== 'undefined';
    }
  }, [selected]);
  return (
    <Row onClick={() => update(value)} style={{
      justifyContent: 'center', alignItems:'center', cursor: 'pointer', margin: '0 15px', ...style,
    }}>
      <Circle>
        {selected === value && <Fill />}
      </Circle>
      <Label>
        <input ref={buttonRef} required name={label} style={{
          position:'absolute',
          opacity: 0,
          pointerEvents: 'none',
        }} type="radio" value={value} />
        {label}
      </Label>
    </Row>
  );
}
export default RadioButtonGroup;