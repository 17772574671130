import styled from 'styled-components';

export const Container = styled.button`
  background: #FE95A2;
  height: 45px;
  min-height: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  outline: none;
  border: none;
`;

export const Label= styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0.62px;
  text-align: center;
  text-transform: uppercase;
`;