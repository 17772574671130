import React, { useState } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core';
import { ValidatedIcon } from '../../../../ThemedInput';
import Row from '../../../../Row';
import {
  Title, Container, MethodContainer, Description, StripeInput,
} from './styled';

const ColoredRadioButton = withStyles({
  root: {
    'color': '#EAAFB7',
    '&$checked': { color: '#EAAFB7' },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function PaymentMethod({
  updateForm, form, total, disabled,
}) {
  const [paymentMethod, setPaymentMethod] = useState(0);

  function handleOrder(data) {
    updateForm('paypalID', data.orderID);
  }
  return (
    <Container disabled={disabled}>
      <MethodContainer onClick={() => setPaymentMethod(0)}>
        <Row>
          <ColoredRadioButton style={{ height: 'min-content', margin: 'auto 0' }} checked={paymentMethod === 0} />
          <Title>Credit Card</Title>
          <Description>This account will be charged for the service once you upload your dress.</Description>
        </Row>
        <StripeInput />
      </MethodContainer>
      <div style={{
        fontWeight: 'bold', fontSize: 22, margin: 10,
      }}
      >OR</div>
      <MethodContainer onClick={() => setPaymentMethod(1)} >
        <ColoredRadioButton style={{ height: 'min-content', margin: 'auto 0' }} checked={paymentMethod === 1} />
        <Title>Paypal</Title>
        <Description>Make a payment online in seconds with PayPal money transfer. All you need is an email address.</Description>
        <PayPalButton options={{ clientId: process.env.GATSBY_PAYPAL_TOKEN }} onApprove={handleOrder} amount={total} />
        {form.paypalID && paymentMethod === 1 && <div style={{
          display: 'flex', alignItems: 'center', marginLeft: 20,
        }}
        >Accepted <img style={{ marginLeft: 10 }} src={ValidatedIcon} /></div>}
      </MethodContainer>
    </Container>
  );
}

export default PaymentMethod;
