import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 6.763vw;
  border-bottom: 1px solid #EDEEF4;
  padding-bottom: 9.420vw;
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 2.899vw;
  color: #A7ABBD;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 5.314vw;
`;

export const ButtonContainer = styled.div`
  height: 11.59vw;
  display: flex;
  width: 100%;
`;

export const Button = styled.div`
  cursor: pointer;
  width: 35.51vw;
  height: 100%;
  background-color: #6B969D;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.382vw;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 4.831vw;
  border-top-right-radius: 1.932vw;
  border-bottom-right-radius: 1.932vw;
`;

export const Input = styled.input`
  width: 100%;
  border-top-left-radius: 1.932vw;
  border-bottom-left-radius: 1.932vw;
  border: 1px solid #EDEEF4;
  padding: 0 4.831vw;
  font-size: 3.382vw;
`;