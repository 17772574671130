import React from 'react';
import Grid from '../../../../Grid';
import Row from '../../../../Row';
import Divider from '../../../../Divider';
import Image from '../../../../ImageBasic';
import { formatAmount } from '../../../../../helpers/Format';
import { BodyText } from './styled.js';
import RemoveSVG from './RemoveIcon.svg';

function CheckoutItem({
  photos: [photo], name,
  salePrice, condition, __id, editCartItem,
}) {
  const formattedPrice = formatAmount(salePrice);
  return (
    <>
      <Grid layout="8fr 1fr" style={{ height: 130, width: '100%' }}>
        <Row style={{ alignItems: 'center' }}>
          <Image style={{
            height: 62, width:'auto', marginRight: 31,
          }} src={photo}/>
          <BodyText>{name}&nbsp;-&nbsp;{condition}</BodyText>
          <BodyText style={{ marginLeft: 'auto' }}>${formattedPrice}</BodyText>
        </Row>
        <Row style={{ alignItems: 'center' }}>
          <Image onClick={() => editCartItem(__id, 0)} style={{
            height: 16, width: 16, cursor:'pointer',
            marginLeft: 56, marginRight: 30,
          }} src={RemoveSVG} />
        </Row>
      </Grid>
      <Divider />
    </>
  );
}

export default CheckoutItem;