import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #A7ABBD;
  letter-spacing: 0;
  line-height: 24px;
  border-bottom: 1px solid #EDEEF4;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #464855;
  letter-spacing: 0;
  line-height: 24px;
  border-bottom: 1px solid #C0C4D1;
  width: 100%;
  :disabled {
    background-color: white;
  }
`;
export const InputTitle = styled.div`
  margin-top: 0.9662vw;
  font-family: Lato-Medium;
  font-size: 2.899vw;
  color: #A3A3A3;
  letter-spacing: 0.3px;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;