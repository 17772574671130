import styled from 'styled-components';

export const CheckoutButton = styled.button`
  border: none;
  -webkit-appearance: none; 
  background: #FE95A2;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 4.348vw;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 4.831vw;
  width: 89.13vw;
  height: 15.46vw;
  cursor: pointer;
  margin: auto;
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7.246vw;
  }
  > img {
    margin-right: 7.246vw;
  }
`;

export const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5.314vw;
  margin-top: 18.12vw;
  padding-bottom: 9.420vw;
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #898EA2;
  letter-spacing: 0;
  line-height: 5.314vw;
  align-self: flex-start;
  margin-bottom: 3.623vw;
`;

export const CheckoutItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #EDEEF4;
  padding: 4.831vw 0;
`;

export const Image = styled.img`
  width: 31.40vw;
  height: 21.74vw;
  object-fit: contain;
`;

export const Name = styled.div`
text-transform: capitalize;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.382vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.797vw;
`;

export const Color = styled.div`
text-transform: capitalize;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.382vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.797vw;
`;

export const Material = styled.div`
text-transform: capitalize;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.382vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.797vw;
`;

export const Price = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 3.382vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.797vw;
`;

export const Remove = styled.img`
  width: 3.865vw;
  height: 3.865vw;
  object-fit: contain;
  margin-left: 2.415vw;
  cursor: pointer;
`;
