import styled from 'styled-components';

export const Container = styled.div`
  width: 470px;
`;

export const Heading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-size: 12px;
  color: #6B969D;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const PlainInput = styled.input`
  height: calc(44px - 2px);
  outline: none;
  background: #FFFFFF;
  border: 1px solid #F3F0EB;
  padding: 0 10px;
  width: calc(100% - 20px);
  font-family: sofia-pro, sans-serif;
  font-size: 14px;
  color: #124C56;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-transform: uppercase;
`;