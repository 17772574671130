import React from 'react';
import { Left, Right } from '../../Summary/styled';
import Divider from '../../../../Divider';
import Image from '../../../../ImageBasic';
import Row from '../../../../Row';
import Column from '../../../../Column';
import { formatAmount } from '../../../../../helpers/Format';
// import { ItemPreview } from './styled.js';

function OrderSummary({ items = [] }) {
  return (
    <Column style={{ width :'100%', marginBottom : 30 }}>
      <Left>Order Summary</Left>
      <Divider style={{ marginTop: 14 }} />
      {
        items.map(({
          photos:[photo] = [], name, condition, salePrice,
        }) => (
          <>
            <Row style={{
              margin: '30px 0', alignItems:'center' , width: '100%',
            }} key={name}>
              <Image style={{
                height: 88, width: 'auto', objectFit:'cover',
                marginRight: 15,
              }} src={photo} />
              <Right style={{ width:153, marginRight:'auto' }}>{name} - {condition}</Right>
              <Right>${formatAmount(salePrice)}</Right>
            </Row>
            <Divider/>
          </>
        ))
      }
    </Column>
  );
}

export default OrderSummary;